<template>
  <div>
    <v-list class="py-0">
      <v-btn
        :to="{ name: 'parser_need_approve_posts', params: { name: $route.params.name } }"
        :class="{ 'v-btn--active': subIsActive(`/parser/${$route.params.name}/parser_post_list`) }"
        block
        flat
        large
        class="ma-0"
      >
        Parser Post list
      </v-btn>
      <!--      :class="{ 'v-btn&#45;&#45;active': subIsActive('/parser/enable_parser') }"-->
      <v-btn
        :to="{ name: 'enable-parser' , params: { name: $route.params.name }}"
        block
        flat
        large
        class="ma-0"
      >
        Enable Parser
      </v-btn>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "ParserAside",
  methods: {
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0
      })
    }
  }
}
</script>
