<template>
  <v-container fluid grid-list-xl>
    <v-layout row>
      <v-flex xs12 sm2>
        <parser-aside />
      </v-flex>

      <v-flex xs12 sm10>
        <v-layout row>
          <v-flex xs12>
            <v-btn-toggle class="table-top-menu">
              <v-btn
                v-for="item in getActiveParsers()"
                :key="item.value"
                :to="{ name: 'parser_need_approve_posts', params: { name: item.value } }"
                :class="{ 'v-btn--active': subIsActive(`/parser/${item.value}/`) }"
                flat
                large
                class="ma-0"
                v-text="item.title"
              />
            </v-btn-toggle>
          </v-flex>
          <v-btn
            v-if="showRunParserButton"
            class="v-btn success v-btn__content"
            @click="runParser()"
          >
            Run "{{ this.$route.params.name }}" parser
          </v-btn>
        </v-layout>
        <router-view />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ParserAside from '@/components/Parser/ParserAside'
import { runParser } from "@/api/parser"
export default {
  name: "Parser",
  components: {
    ParserAside
  },
  data() {
    return {
      parserList: [
        {title: "Telegram", value: "telegram", active: false},
        {title: "Reddit", value: "reddit", active: false},
        {title: "9gag", value: "9gag", active: true},
      ],
    }
  },
  computed: {
    showRunParserButton () {
      return this.$route.params.name === '9gag'
    }
  },
  methods: {
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0
      })
    },

    getActiveParsers() {
      return this.parserList.filter(item => item.active)
    },

    async runParser() {
      const parser = this.$route.params.name
      await runParser(parser)
      this.$notificationShow(
          `${parser} parse task successfully queued`
      )
    }
  }
}
</script>

<style scoped>
.table-top-menu >>>.v-btn{
  min-width: 140px;
}
</style>
